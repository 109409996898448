<template>
  <div class="business-areas">
    <div class="home-box-title">我们为实现您的需求，提供<span data-aos="fade-down">一站式服务</span></div>
    <div class="business-areas-box">
      <div v-for="item in businessVoList" :key="`businessVoList-${item.id}`" class="business-areas-item" data-aos="flip-left" @click="jumpPage('BusinessAreas', item.secondId)">
        <img :src="item.imgPath" :alt="item.businessName" />
        <div class="areas">
          <div class="areas-name">{{ item.businessName }}</div>
          <div class="areas-info">
            <div>{{ item.businessIntroduc }}</div>
            <div>了解更多 ...</div>
          </div>
        </div>
      </div>
      <!-- <div class="business-areas-item" data-aos="flip-left" @click="jumpPage('BusinessAreas')">
        <div class="areas">
          <div class="areas-name">气象服务</div>
          <div class="areas-info">
            <div>十年气象行业服务经验、深耕气象领域、专业前端采集及后端平台服务商； 基于气象数值预报和人工智能技术、提供高精度气象数据。</div>
            <div>了解更多 ...</div>
          </div>
        </div>
      </div>
      <div class="business-areas-item" data-aos="flip-right">
        <div class="areas">
          <div class="areas-name">环境监测</div>
          <div class="areas-info">
            <div>大气环境监测、水体质量监测、标准站及网格化监测微。</div>
            <div>了解更多 ...</div>
          </div>
        </div>
      </div>
      <div class="business-areas-item" data-aos="flip-left">
        <div class="areas">
          <div class="areas-name">海洋监测</div>
          <div class="areas-info">
            <div>海洋环境在线监测、观测技术及化学分析技术的研发和产业化应用。</div>
            <div>了解更多 ...</div>
          </div>
        </div>
      </div>
      <div class="business-areas-item" data-aos="flip-right">
        <div class="areas">
          <div class="areas-name">物联网系统</div>
          <div class="areas-info">
            <div>优质产品设备、远距离数据无线传输方案、北斗卫星数据传输平台——系统整体解决方案提供者。</div>
            <div>了解更多 ...</div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script lang="ts" src="./business-areas.ts"></script>

<style lang="scss" scoped>
.business-areas {
  &-box {
    display: flex;
  }
  &-item {
    width: 480px;
    height: 655px;
    position: relative;
    cursor: pointer;
    color: #fff;
    overflow: hidden;
    background-color: #000;
    img {
      object-fit: cover;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      transition-duration: 1.2s;
      opacity: 0.8;
    }
    &:hover {
      .areas-info {
        opacity: 1;
        transition-duration: 1s;
      }
      img {
        transform: scale(1.2);
      }
    }
    .areas {
      position: absolute;
      width: 344px;
      height: 206px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      &-name {
        font-size: 36px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 38px;
      }
      &-info {
        opacity: 0;
        > div {
          &:first-child {
            margin-bottom: 58px;
          }
          &:last-child {
            font-weight: 18px;
            font-weight: bold;
          }
        }
      }
    }
  }
}
</style>
