import axios from "axios";
const ConfigBaseURL = "http://120.79.232.93:8081/";

export const Service = axios.create({
  timeout: 5000,
  baseURL: ConfigBaseURL,
  method: "post",
  headers: {
    "Content-Type": "application/json;charset=UTF-8"
  }
});

Service.interceptors.request.use(
  config => {
    // do something before request is sent
    return config;
  },
  error => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
Service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data;

    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 1) {
      // return Promise.reject(new Error(res.message || "Error"));
      return new Error(res.message || "Error");
    } else {
      return res.data;
    }
  },
  error => {
    console.log("err" + error); // for debug
    return Promise.reject(error);
  }
);

//转换类型
declare module "axios" {
  interface AxiosInstance {
    (config: AxiosRequestConfig): Promise<any>;
  }
}
export default Service;
