<template>
  <div class="navigation">
    <div class="navigation-logo" data-aos="fade-right">
      <img src="@/assets/image/logo.png" alt="" @click="jumpPage('Home')" />
    </div>
    <div class="navigation-items" data-aos="fade-down">
      <div
        v-for="(item, index) in navigation"
        :key="`navigation-${index}`"
        :class="{ 'has-child': item.name === 'BusinessAreas', active: isActive(item.name) }"
        @click="item.name === 'BusinessAreas' ? '' : jumpPage(item.name)"
      >
        {{ item.label }}
      </div>
      <div class="child-navigation" v-if="business">
        <div v-for="(item, index) in Object.keys(business)" :key="`business-${index}`" class="child-navigation-item">
          <div>{{ item }}</div>
          <div v-for="item1 in business[item]" :key="item1.id" @click="jumpPage('BusinessAreas', item1.id)">{{ item1.businessName }}</div>
        </div>
        <!-- <div class="child-navigation-item">
          <div>气象服务</div>
          <div @click="jumpPage('BusinessAreas')">电力气象</div>
          <div>水利气象</div>
          <div>环保气象</div>
          <div>交通气象</div>
          <div>航空气象</div>
        </div>
        <div class="child-navigation-item">
          <div>环境监测</div>
          <div>大气环境监测</div>
          <div>水污染监测</div>
          <div>土壤环境监测</div>
        </div>
        <div class="child-navigation-item">
          <div>海洋监测</div>
          <div>在线监测系统</div>
        </div>
        <div class="child-navigation-item">
          <div>物联网系统</div>
          <div>NB-IOT物联网</div>
          <div>远距离数据无线传输方案</div>
          <div>北斗卫星数据传输平台</div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script lang="ts" src="./index.ts"></script>
<style lang="scss" scoped>
.navigation {
  position: relative;
  height: 66px;
  display: flex;
  align-items: center;
  z-index: 5;
  border-bottom: 1px solid rgb(220 220 220 / 45%);
  box-shadow: 0 0 8px rgb(0 0 0 / 10%);
  &-logo {
    position: absolute;
    height: 40px;
    margin-left: 82px;
    margin-right: 240px;
    z-index: 5;
    img {
      height: 100%;
      cursor: pointer;
    }
  }
  &-items {
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 570px;
    &::before {
      content: "";
      position: absolute;
      display: block;
      width: inherit;
      height: 66px;
      left: 0;
      background-color: #fff;
      z-index: 4;
    }
    > div {
      height: 66px;
      line-height: 66px;
      margin-right: 75px;
      cursor: pointer;
      z-index: 5;
      transition-duration: 0.5s;
      &:hover {
        color: $focusFontColor;
        &.has-child ~ .child-navigation {
          top: 66px;
          color: inherit;
        }
      }
      &.active {
        color: $focusFontColor;
      }
    }
    .child-navigation {
      position: absolute;
      height: max-content;
      top: -500px;
      left: 0;
      right: 0;
      margin: 0 auto;
      background-color: #fff;
      transition-duration: 0.5s;
      z-index: 3;
      box-sizing: border-box;
      padding-left: 320px;
      display: flex;
      border-top: 1px solid rgb(220 220 220 / 45%);
      border-bottom: 1px solid rgb(220 220 220 / 45%);
      box-shadow: 0 0 8px rgb(0 0 0 / 10%);
      &:hover {
        top: 66px;
        color: inherit;
      }
      &-item {
        padding-left: 33px;
        line-height: normal;
        width: 230px;
        & {
          + div {
            border-left: 1px solid rgb(220 220 220 / 45%);
          }
          :first-child {
            font-size: 16px;
            font-weight: bold;
            padding-top: 20px;
            padding-bottom: 30px;
            cursor: default;
          }
          :not(:first-child) {
            padding-bottom: 20px;
            font-size: 14px;
            transition-duration: 0.5s;
            &:hover {
              color: $focusFontColor;
            }
          }
        }
      }
    }
  }
}
@keyframes navigation-item {
  100% {
    color: $focusFontColor;
  }
}
</style>
