<template>
  <div class="case-shows">
    <div class="home-box-title">专注创新,为您量身<span data-aos="fade-down">定制</span>解决方案</div>
    <swiper loop :autoplay="{ delay: 5500, disableOnInteraction: false }" :pagination="{ clickable: true }">
      <swiper-slide v-for="(item, index) in solutionList" :key="`case-${index}`" @click="jumpPage('SolutionDetails', item.id)">
        <div class="banner-item">
          <div class="case-info">
            <div class="ellipsis">{{ item.imgTitle }}</div>
            <div class="ellipsis-3">{{ item.imgIntroduce }}</div>
            <div>了解更多...</div>
          </div>
          <div class="case-img">
            <img :src="imgBaseUrl + item.imgPath" :alt="item.imgTitle" />
          </div>
        </div>
      </swiper-slide>
      <!-- <swiper-slide @click="jumpPage('SolutionDetails')">
        <div class="banner-item">
          <div class="case-info">
            <div>雷电监测预警系统</div>
            <div>
              极端天气与自然灾害给电网的安全运行造成巨大影响。其中，雷电是造成电网设备设施跳闸、损毁等故障最重要的原因，是引发输电线路故障数量最多的自然灾害。
            </div>
            <div>了解更多...</div>
          </div>
          <div class="case-img">
            <img src="@/assets/image/temporary/77a788dc066ffaa3df939e6f0ca997a.jpg" alt="" />
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="banner-item">
          <div class="case-info">
            <div>环境气象物联监测综合系统</div>
            <div>
              环境污染给生态系统造成直接破坏。云端高科致力于服务环保领域，专业提供实时气象资料收集处理、高分辨率区域数值预报等模式，为企业生产助力，为生态环境改善赋能。
            </div>
            <div>了解更多...</div>
          </div>
          <div class="case-img">
            <img src="@/assets/image/temporary/a1fc9a59e8dac4c23437302d6595241.jpg" alt="" />
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="banner-item">
          <div class="case-info">
            <div>航空气象预警系统</div>
            <div>
              云端高科搭建综合气象指标预警系统，将实况报警、雷达识别、风暴追踪信息，大风、强降水、雷暴落区预报信息融合。全方位满足航空客户需求、为用户提供信息推送服务。
            </div>
            <div>了解更多...</div>
          </div>
          <div class="case-img">
            <img src="@/assets/image/temporary/22c1294329f13d1cd82b2f7cc4815b4.jpg" alt="" />
          </div>
        </div>
      </swiper-slide> -->
    </swiper>
  </div>
</template>

<script lang="ts" src="./case-shows.ts"></script>

<style lang="scss" scoped>
.case-shows {
  background-image: url("~@/assets/image/case-shows-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  ::v-deep {
    .swiper-pagination-bullet {
      background: #fff;
      opacity: 0.88;
    }
    .swiper-pagination-bullet-active {
      background-color: $focusFontColor;
      width: 20px;
      border-radius: 20px;
      opacity: 1;
    }
  }
  .banner {
    &-item {
      position: relative;
      height: 620px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .case-info {
        position: absolute;
        top: 155px;
        left: 296px;
        width: 540px;
        height: 290px;
        background-color: #fff;
        padding: 60px 70px;
        box-sizing: border-box;
        z-index: 2;
        > div {
          &:first-child {
            font-size: 30px;
            font-weight: bold;
            padding-bottom: 22px;
          }
          &:nth-child(2) {
            font-size: 14px;
            margin-bottom: 44px;
          }
          &:last-child {
            font-size: 18px;
            color: #3aaffa;
          }
        }
      }
      .case-img {
        position: absolute;
        width: 927px;
        height: 520px;
        right: 297px;
        top: 36px;
        z-index: 1;
        img {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
}
</style>
