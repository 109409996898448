/**
 * @file 请求函数文件
 */

import request from "./request";

/**
 * get 请求
 *
 * @param {string} url 请求地址
 * @param {string} params 请求参数
 * @returns {Object}
 */
export function getByUrl(url: string, params = {}) {
  return request({
    url,
    method: "get",
    params
  });
}

/**
 * post 请求
 *
 * @param {string} url 请求地址
 * @param {string} data 请求参数
 * @returns {Object}
 */
export function postByUrl(url: string, data = {}) {
  return request({
    url,
    method: "post",
    data
  });
}

// /**
//  * form 请求
//  *
//  * @param {string} url 请求地址
//  * @param {string} params 请求参数
//  * @returns {Object}
//  */
// export function formByUrl(url: string, params = {}) {
//   const data = new FormData();
//   Object.keys(params).forEach(key => {
//     data.append(key, params[key]);
//   });
//   return request({
//     url,
//     method: "post",
//     data
//   });
// }
