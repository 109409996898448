import { createRouter, createWebHistory, createWebHashHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home/index.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/business-areas/:typeId?",
    name: "BusinessAreas",
    component: () => import(/* webpackChunkName: "BusinessAreas" */ "../views/BusinessAreas/index.vue")
  },
  {
    path: "/solution-details/:solutionId?",
    name: "SolutionDetails",
    component: () => import(/* webpackChunkName: "SolutionDetails" */ "../views/SolutionDetails/index.vue")
  },
  {
    path: "/case-details/:id?",
    name: "CaseDetails",
    component: () => import(/* webpackChunkName: "CaseDetails" */ "../views/CaseDetails/index.vue")
  },
  {
    path: "/news-information/:id?",
    name: "NewsInformation",
    component: () => import(/* webpackChunkName: "NewsInformation" */ "../views/NewsInformation/index.vue")
  },
  {
    path: "/news-details/:newsId",
    name: "NewsDetails",
    component: () => import(/* webpackChunkName: "NewsDetails" */ "../views/NewsDetails/index.vue")
  },
  {
    path: "/product-list/:firstTypeId?/:secondTypeId?",
    name: "ProductList",
    component: () => import(/* webpackChunkName: "ProductList" */ "../views/ProductList/index.vue")
  },
  {
    path: "/product-details/:productsId?",
    name: "ProductDetails",
    component: () => import(/* webpackChunkName: "ProductDetails" */ "../views/ProductDetails/index.vue")
  },
  {
    path: "/about-us/:id?",
    name: "AboutUs",
    component: () => import(/* webpackChunkName: "AboutUs" */ "../views/AboutUs/index.vue")
  }
];

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
  routes
});

export default router;
