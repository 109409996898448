import { defineComponent, onMounted, nextTick } from "vue";
import AOS from "aos";
import "aos/dist/aos.css";
import Navigation from "@/components/Navigation/index.vue";
import SiteMap from "@/components/SiteMap/index.vue";
// import { useStore } from "vuex";
// ProductList ProductDetails NewsDetails
export default defineComponent({
  name: "App",
  components: {
    Navigation,
    SiteMap
  },
  setup() {
    // const store = useStore();
    // store.dispatch("getImgBaseUrl");
    onMounted(() => {
      nextTick(() => {
        AOS.init({
          duration: 1000,
          mirror: true
        });
      });
    });
  }
});
