import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  name: "VideoBox",
  setup() {
    const homeVideo = ref<HTMLVideoElement | null>(null);
    onMounted(() => {
      const player = homeVideo.value as HTMLVideoElement;
      player.autoplay = true;
      player.loop = true;
      player.muted = true;
      player.play();
    });
    return {
      homeVideo
    };
  }
});
