import { defineComponent, reactive, toRefs } from "vue";
import { useRouter } from "vue-router";

interface Data {
  jumpPage: (name: string, typeId: number) => void;
}

export default defineComponent({
  name: "HomeBusinessAreas",
  props: {
    businessVoList: Array
  },
  setup() {
    const router = useRouter();

    const data: Data = reactive({
      jumpPage: (name, typeId) => {
        router.push({ name, params: { typeId } });
      }
    });
    return {
      ...toRefs(data)
    };
  }
});
