<template>
  <navigation></navigation>
  <router-view />
  <site-map></site-map>
</template>
<script lang="ts" src="./App.ts"></script>
<style lang="scss">
#app {
  font-family: -apple-system, SF UI Text, Arial, PingFang SC, Hiragino Sans GB, Microsoft YaHei, WenQuanYi Micro Hei, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $defaultFontColor;
  font-size: $defaultFontSize;
  position: relative;
}
html,
body {
  margin: 0;
  padding: 0;
}
</style>
