import { defineComponent, reactive, toRefs } from "vue";
import { useRoute, useRouter } from "vue-router";
import { getByUrl } from "@/utils/http";

interface Data {
  navigation: {
    label: string;
    name: string;
  }[];
  business: {
    [propName: string]: Array<{
      businessIntroduce: string;
      businessName: string;
      businessTitle: string;
      firstId: number;
      id: number;
      imgPath: null;
    }>;
  } | null;
  jumpPage: (name: string, typeId: number) => void;
  isActive: (name: string) => boolean;
}

export default defineComponent({
  name: "Navigation",
  setup() {
    const router = useRouter();
    const route = useRoute();

    const data: Data = reactive({
      business: null,
      navigation: [
        { label: "首页", name: "Home" },
        { label: "业务领域", name: "BusinessAreas" },
        { label: "产品展示", name: "ProductList" },
        { label: "新闻资讯", name: "NewsInformation" },
        { label: "关于我们", name: "AboutUs" }
      ],
      jumpPage: (name, typeId) => {
        router.push({ name, params: { typeId } });
      },
      isActive: name => {
        return route.name === name;
      }
    });

    const queryBusiness = () => {
      getByUrl("/businessArea/getBusinesstype").then(res => {
        data.business = res;
      });
    };

    queryBusiness();

    return {
      ...toRefs(data)
    };
  }
});
