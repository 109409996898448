<template>
  <div class="video-box">
    <video ref="homeVideo" loop="loop" autoplay="autoplay" style="width:100%;vertical-align:middle;">
      <source src="@/assets/media/home-video-no-person.mp4" type="video/mp4" />
    </video>
    <div class="info">
      <div>气象和环境相关领域 整体解决方案</div>
    </div>
  </div>
</template>
<script lang="ts" src="./video-box.ts"></script>
<style lang="scss" scoped>
.video-box {
  position: relative;
  height: 904px;
  overflow: hidden;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 2;
    background-color: #000;
    opacity: 0.2;
  }
  video {
    position: absolute;
    // top: -150px;
    z-index: 1;
  }
  .info {
    position: absolute;
    left: 132px;
    top: 286px;
    color: #ffffff;
    z-index: 3;
    div {
      width: 690px;
      height: 164px;
      font-size: 72px;
      font-weight: bold;
      line-height: 98px;
    }
  }
}
</style>
