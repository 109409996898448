import { defineComponent, reactive, toRefs } from "vue";
import { useRouter } from "vue-router";

interface Data {
  jumpPage: (url: string, isOpen: boolean) => void;
}

export default defineComponent({
  name: "SiteMap",
  props: {
    businessVoList: Array
  },
  setup() {
    const router = useRouter();

    const data: Data = reactive({
      jumpPage: (url, isOpen) => {
        isOpen ? window.open(url) : (window.location.href = url);
      }
    });
    return {
      ...toRefs(data)
    };
  }
});
