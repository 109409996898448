<template>
  <div class="news-box">
    <div class="home-box-title">与您分享<span data-aos="fade-down">最新</span>动态</div>
    <div>
      <div v-for="item in newsList" :key="item.id" class="news-item" data-aos="zoom-in-right" @click="jumpPage('NewsDetails', item.id)">
        <div class="news-img">
          <img :src="imgBaseUrl + item.imgPath" :alt="item.imgTitle" />
        </div>
        <div class="news-info">
          <div>{{ item.imgTitle }}</div>
          <div>{{ item.imgSubtitle }}</div>
          <div>{{ formattingDate(item.imgTime) }} {{ item.imgSource }}</div>
        </div>
      </div>
      <!-- <div class="news-item" data-aos="zoom-in-up">
        <div class="news-img">
          <img src="@/assets/image/temporary/20210309153813.jpg" alt="" />
        </div>
        <div class="news-info">
          <div>2020参与高交会的展会</div>
          <div>高交会，我们来了</div>
          <div>2020年5月10日 展会活动</div>
        </div>
      </div>
      <div class="news-item" data-aos="zoom-in-left">
        <div class="news-img">
          <img src="@/assets/image/temporary/20201027144228.jpg" alt="" />
        </div>
        <div class="news-info">
          <div>深圳市智慧杆产业促进会</div>
          <div>作为深圳市智慧杆产业促进会协会理事，公司领导受邀参观智慧杆产业促进协会</div>
          <div>2020年2月21日 展会活动</div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script lang="ts" src="./news-box.ts"></script>

<style lang="scss" scoped>
.news-box {
  // background-image: url("~@/assets/image/news-box-bg.png");
  // background-size: cover;
  // background-repeat: no-repeat;
  // background-position: center;
  // padding-bottom: 60px;
  > div {
    &:last-child {
      display: flex;
      justify-content: center;
    }
  }
  .news-item {
    position: relative;
    width: 518px;
    border-radius: 5px;
    background-color: #fff;
    overflow: hidden;
    cursor: pointer;
    transition-duration: 0.3s;
    box-shadow: 0px 3px 26px 1px rgba(5, 48, 88, 0.1);
    padding: 18px;
    & + .news-item {
      margin-left: 20px;
    }
    &:hover {
      transform: translateY(-8px);
    }
    .news-img {
      width: 100%;
      height: 434px;
      position: relative;
      z-index: 1;
      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition-duration: 0.5s;
      }
    }
    .news-info {
      padding: 44px 12px;
      font-size: 14px;
      color: #666;
      > div {
        &:first-child {
          text-align: center;
          font-size: 18px;
          font-weight: bold;
          padding-bottom: 20px;
          color: $defaultFontColor;
          min-height: 40px;
          max-height: 40px;
        }
        &:nth-child(2) {
          height: 34px;
        }
        &:last-child {
          padding-top: 18px;
        }
      }
    }
  }
}
</style>
