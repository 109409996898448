import { createStore } from "vuex";
import { getByUrl } from "@/utils/http";

export default createStore({
  state: {
    imgBaseUrl: ""
  },
  mutations: {
    IMG_BASE_URL(state, imgBaseUrl) {
      state.imgBaseUrl = imgBaseUrl;
    }
  },
  actions: {
    getImgBaseUrl(context, request) {
      return new Promise((resolve, reject) => {
        getByUrl("/getSite/getSite")
          .then(res => {
            context.commit("IMG_BASE_URL", res);
            resolve(res);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    }
  },
  modules: {},
  getters: {
    imgBaseUrl: state => state.imgBaseUrl
  }
});
