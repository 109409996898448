<template>
  <div class="home">
    <video-box></video-box>
    <news-box :newsList="newsList"></news-box>
    <business-areas :businessVoList="businessVoList"></business-areas>
    <case-shows :solutionList="solutionList"></case-shows>
  </div>
</template>

<script lang="ts" src="./index.ts"></script>

<style lang="scss" scoped>
.home {
  ::v-deep {
    .home-box-title {
      color: #282828;
      font-size: 40px;
      font-weight: bold;
      text-align: center;
      padding-top: 100px;
      padding-bottom: 50px;
      span {
        color: #3aaffa;
      }
    }
  }
}
</style>
