import { defineComponent, reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
import { Swiper, SwiperSlide } from "swiper/vue";
import { mapGetters } from "vuex";
import SwiperCore, { Pagination, Autoplay } from "swiper";
import "swiper/swiper.scss";
import "swiper/components/pagination/pagination.scss";

SwiperCore.use([Pagination, Autoplay]);

interface Data {
  jumpPage: (name: string, solutionId: number) => void;
}

export default defineComponent({
  name: "HomeCaseShows",
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    solutionList: Array
  },
  computed: {
    ...mapGetters(["imgBaseUrl"])
  },
  setup() {
    const router = useRouter();

    const data: Data = reactive({
      jumpPage: (name, solutionId) => {
        router.push({ name, params: { solutionId } });
      }
    });
    return {
      ...toRefs(data)
    };
  }
});
