import { defineComponent, reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
import { mapGetters } from "vuex";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

interface Data {
  jumpPage: (name: string, newsId: number) => void;
}

export default defineComponent({
  name: "HomeNewsBox",
  props: {
    newsList: Array
  },
  computed: {
    ...mapGetters(["imgBaseUrl"])
  },
  setup(props) {
    const router = useRouter();

    const data: Data = reactive({
      jumpPage: (name, newsId) => {
        router.push({ name, params: { newsId } });
      },
      formattingDate(date: string) {
        return dayjs(date, "YYYY-MM-DD").format("YYYY年MM月DD日");
      }
    });

    return {
      ...toRefs(data)
    };
  }
});
