import { defineComponent, reactive, toRefs } from "vue";
import VideoBox from "./video-box.vue";
import NewsBox from "./news-box.vue";
import BusinessAreas from "./business-areas.vue";
import CaseShows from "./case-shows.vue";
import { getByUrl } from "@/utils/http";

interface Data {
  businessVoList: Array<{
    businessIntroduc: string;
    businessName: string;
    id: number;
    imgPath: string;
    secondId: number;
  }>;
  newsList: Array<{
    id: number;
    imgDetails: null;
    imgPath: string;
    imgSource: string;
    imgSubtitle: string;
    imgTime: string;
    imgTitle: string;
    isHomePage: null;
    newsId: null;
  }>;
  solutionList: Array<{
    businessTypeId: null;
    id: number;
    imgDetails: null;
    imgIntroduce: string;
    imgPath: string;
    imgTitle: string;
    isHomePage: null;
  }>;
}

export default defineComponent({
  name: "Home",
  components: {
    VideoBox,
    NewsBox,
    BusinessAreas,
    CaseShows
  },
  setup() {
    const data: Data = reactive({
      businessVoList: [],
      newsList: [],
      solutionList: []
    });
    getByUrl("/homePage/index").then(res => {
      const { newsList, solutionList, businessVoList } = res;
      data.newsList = newsList;
      data.solutionList = solutionList;
      data.businessVoList = businessVoList;
    });
    return {
      ...toRefs(data)
    };
  }
});
